/* Footer.css */
.container-footer-border {
  padding-top: 10px;
  border-top: 1px solid gray;
  flex-shrink: 0; /* Prevent the footer from shrinking */
}
.footer-gradient {
  background: linear-gradient(to top, #000000, #212529);
}
.footer-link {
  color: #ffffff;
  text-decoration: none;
}
.footer-link:hover {
  color: #c5c1c1;
}
