/* Blog.css */
.card-background {
  background: transparent;
  border: none;
}

/* Hover effects for the cards on desktop devices */
@media (min-width: 1024px) {
  .custom-hover {
    transition: transform 0.3s;
  }
  .custom-hover:hover {
    transform: scale(1.05);
  }
  .custom-hover:hover .card-title,
  .custom-hover:hover .card-text {
      color: #c5c1c1;
  }
}
