/* BlogNavbar.css */

/* Below is the code for general styling of the navbar */
a {
  color: #c5c1c1;
  margin: 0 2rem;
  text-decoration: none;
}
a:hover {
  color: #ffffff;
}
.active-style {
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}
.active-style:hover {
  color: #858688;
}
