/* General styles for articles */
.custom-bold {
  font-weight: bold;
}

/* By default, make the image full-width */
.custom-img {
  width: 100% !important;
}
/* Table styling */
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: center;
}
td {
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
}

/* Media Queries */
/* On large screens, make it 75% */
@media (min-width: 992px) {

  /* Bootstrap 4 lg breakpoint */
  .custom-img {
    width: 75% !important;
  }
}

.article-link {
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.article-link:hover {
  color: #c4c1c1;
}
