/* App.css */
@import './fonts.css';

/* Font-family for the whole app */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  /* Reset default margin to prevent unwanted spacing */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  scroll-behavior: smooth;
}

.main-content {
  flex: 1;
  /* Expand the main content to take remaining space */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.blur-circle {
  position: absolute;
  z-index: 1;
  /* Set a lower z-index for the blurry circle */
}

.transparent-navbar {
  background-color: transparent !important;
}
